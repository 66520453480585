import { FormattedMessage } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { AppDispatch, RootState } from '../../../configuration/setup/store';
import { FunctionProperties, NonFunctionProperties } from '../../../common/typescriptutils/typeManipulation';
import { connect } from 'react-redux';
import { LabeledNumberInput } from '../../common/displaycomponents/LabeledNumberInput';
import { getCurrency, getVehicleCostsPerKilometer } from '../../../application/redux/options/optionsSelectors';
import { optionsActions } from '../../../application/redux/options/optionsReducer';
import getSymbolFromCurrency from 'currency-symbol-map';
import { LabeledSelect } from '../../common/displaycomponents/LabeledSelect';
import { supportedCurrencies } from './supportedCurrencies';
import { SelectOption } from '@rio-cloud/rio-uikit/types';

interface CostOptionProps {
    vehicleCostsPerKilometer: number;
    currency: string;
    updateVehicleCostsPerKilometer: (vehicleCostsPerKilometer: number) => void;
    updateCurrency: (currency: string) => void;
}

const currencyOptionLabels: SelectOption[] = supportedCurrencies.map(it => ({
    id: it,
    label: `${it} / ${getSymbolFromCurrency(it)}`,
}));

const CostOptions = (props: CostOptionProps) => {
    const { currency, vehicleCostsPerKilometer, updateVehicleCostsPerKilometer, updateCurrency } = props;
    const onChangeVehicleCostsPerKilometer = (value?: number) => {
        if (value != null) {
            updateVehicleCostsPerKilometer(value);
        }
    };
    const onChangeCurrency = (value?: string) => {
        if (value != null) {
            updateCurrency(value);
        }
    };
    return (
        <ExpanderPanel
            className={'VehicleOptions margin-bottom-0'}
            title={<FormattedMessage id={'intl-msg:routeplanning.label.costOptions'} />}
            iconLeft
            bsStyle={'separator'}
        >
            <div className={'display-flex align-items-end margin-bottom-10'}>
                <LabeledNumberInput
                    className={'flex-1-1-0 padding-right-5'}
                    labelId={'intl-msg:routeplanning.label.vehicleCostsPerKilometer'}
                    value={vehicleCostsPerKilometer}
                    integerOnly={false}
                    unit={getSymbolFromCurrency(currency)}
                    onChange={onChangeVehicleCostsPerKilometer}
                />
                <LabeledSelect
                    className={'padding-left-5'}
                    label={<FormattedMessage id={'intl-msg:routeplanning.label.currency'} />}
                    optionLabels={currencyOptionLabels}
                    selectedOption={currency}
                    onChange={onChangeCurrency}
                    dropup={true}
                    dropdownClassName={'left-auto right-0'}
                />
            </div>
        </ExpanderPanel>
    );
};

const mapStateToProps = (state: RootState): NonFunctionProperties<CostOptionProps> => ({
    vehicleCostsPerKilometer: getVehicleCostsPerKilometer(state),
    currency: getCurrency(state),
});
const mapDispatchToProps = (dispatch: AppDispatch): FunctionProperties<CostOptionProps> => ({
    updateVehicleCostsPerKilometer: (vehicleCostsPerKilometer: number) =>
        dispatch(optionsActions.setVehicleCostsPerKilometer(vehicleCostsPerKilometer)),
    updateCurrency: (currency: string) => dispatch(optionsActions.setCurrency(currency)),
});

export const CostOptionsContainer = connect(mapStateToProps, mapDispatchToProps)(CostOptions);
