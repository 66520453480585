import { useIntl } from 'react-intl';
import AutoSuggest from '@rio-cloud/rio-uikit/AutoSuggest';
import { AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/types';
import { SortableHandle } from 'react-sortable-hoc';

import { Address, Waypoint } from '../../application/redux/search/searchReducer';
import { debouncedGaPush, gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

export interface WaypointInputProps {
    waypoint: Waypoint;
    suggestedAddresses: Address[];
    onInputValueChanged: Function;
    onSuggestionSelected: Function;
    onInputCleared: Function;
    onRemove: () => void;
    allowRemove: boolean;
    icon: string;
}

const DragHandle = SortableHandle(() => (
    <div className={'btn btn-muted btn-sm btn-icon-only cursor-grab padding-left-0 margin-left--10 width-25'}>
        <span className={'rioglyph rioglyph-option-vertical'} />
    </div>
));

const mapSuggestionIconType = (addressType: string) => {
    switch (addressType) {
        case 'customer':
            return 'pushpin';
        case 'workshop':
            return 'workshop';
        case 'circle':
        case 'polygon':
            return 'geofence';
        default:
            return 'map-marker';
    }
};

const getFallbackText = (waypoint: Waypoint) => {
    if (waypoint.coordinates) {
        return `${waypoint.coordinates?.lat}, ${waypoint.coordinates?.lng}`;
    }
    return '';
};

export const WaypointInput = (props: WaypointInputProps) => {
    const {
        waypoint,
        suggestedAddresses,
        onInputValueChanged,
        onSuggestionSelected,
        onInputCleared,
        onRemove,
        allowRemove,
        icon,
    } = props;

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        if (waypoint.address !== value) {
            onInputValueChanged(waypoint.id, value);
            debouncedGaPush({
                category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
                action: 'Waypoint Suggestions Requested',
                label: 'Requested Waypoint Suggestions',
            });
        }
    };

    const handleSuggestionSelected = (event: any, { suggestion }: any) => {
        onSuggestionSelected(waypoint.id, suggestion);
        gaPush({
            category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
            action: 'Waypoint Suggestion Selected',
            label: 'Selected Waypoint Suggestion',
        });
    };

    const renderSuggestion = (suggestion: AutoSuggestSuggestion) => {
        return (
            <span>
                <span className={`rioglyph rioglyph-${suggestion.type} margin-right-3`} />
                <span>{suggestion.label}</span>
            </span>
        );
    };

    const handleInputCleared = () => {
        onInputCleared();
        gaPush({
            category: TRACKING_CATEGORIES.WAYPOINT_EDITOR,
            action: 'Clear Waypoint Input Clicked',
            label: 'Clicked Clear Waypoint Input',
        });
    };

    const suggestions = suggestedAddresses.map(address => {
        return {
            ...address,
            label: address.title,
            type: mapSuggestionIconType(address.resultType),
        } as AutoSuggestSuggestion;
    });

    const intl = useIntl();

    const inputProps = {
        onChange: () => {},
        onClear: () => {
            handleInputCleared();
        },
        placeholder: intl.formatMessage({ id: 'intl-msg:routeplanning.label.findAddress' }),
        icon: `rioglyph-${icon}`,
        value: waypoint.address || getFallbackText(waypoint),
    };

    const removeAddon = (
        <div key={'removeAddon'} className={'input-group-addon'} onClick={onRemove}>
            <span className={'rioglyph rioglyph-trash cursor-pointer text-color-gray hover-text-color-danger'} />
        </div>
    );

    const trailingAddons = allowRemove ? [removeAddon] : [];

    return (
        <div className={'padding-top-5 padding-bottom-5 display-flex align-items-center'}>
            <DragHandle />
            <AutoSuggest
                className={'WaypointInput flex-1-0'}
                inputProps={inputProps}
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                onSuggestionSelected={handleSuggestionSelected}
                renderSuggestion={renderSuggestion}
                trailingInputAddons={trailingAddons}
            />
        </div>
    );
};
