import { FormattedMessage } from 'react-intl';
import find from 'lodash/fp/find';

import { Profile } from './VehicleAttributePresets';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { LabeledSelect } from '../../common/displaycomponents/LabeledSelect';
import { SelectOption } from '@rio-cloud/rio-uikit/types';

const vehicleTypeOptionLabels = [
    {
        id: Profile.TRUCK_AND_TRAILER_40T,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.vehicleType.truckAndTrailer40T'} />,
    },
    { id: Profile.TRUCK_11T, label: <FormattedMessage id={'intl-msg:routeplanning.label.vehicleType.truck11T'} /> },
    { id: Profile.TRUCK_7T5, label: <FormattedMessage id={'intl-msg:routeplanning.label.vehicleType.truck7T5'} /> },
    {
        id: Profile.TRANSPORTER_3T5,
        label: <FormattedMessage id={'intl-msg:routeplanning.label.vehicleType.transporter3T5'} />,
    },
    { id: Profile.CAR, label: <FormattedMessage id={'intl-msg:routeplanning.label.vehicleType.car'} /> },
];

export const VehicleTypeSelect = ({ profile, onProfileChange, hasCustomAttributes }: VehicleTypeSelectProps) => {
    const getEditedProfileMessage = () => {
        const findSelectedProfile = find((label: SelectOption) => label.id === profile);
        const selectedProfile = findSelectedProfile(vehicleTypeOptionLabels);

        if (!selectedProfile) {
            return;
        }

        const profileMessage = selectedProfile.label;
        const editMessage = <FormattedMessage id={'intl-msg:routeplanning.label.edited'} />;
        return (
            <span>
                {profileMessage} ({editMessage})
            </span>
        );
    };

    const toggleButtonLabel = hasCustomAttributes && getEditedProfileMessage();

    const handleProfileChange = (selectedOption: string) => {
        if (selectedOption) {
            const selectedProfile = selectedOption as Profile;
            onProfileChange(selectedProfile);
            gaPush({
                category: TRACKING_CATEGORIES.TOUR_OPTIONS_EDITOR,
                action: 'Vehicle Type Selected',
                label: `Selected Vehicle Type::${selectedOption}`,
            });
        }
    };

    return (
        <div className={'display-flex align-items-end margin-bottom-10'}>
            <LabeledSelect
                className={'width-100pct'}
                label={<FormattedMessage id={'intl-msg:routeplanning.label.vehicleType'} />}
                optionLabels={vehicleTypeOptionLabels}
                selectedOption={profile}
                toggleButtonLabel={toggleButtonLabel}
                onChange={handleProfileChange}
            />
        </div>
    );
};

interface VehicleTypeSelectProps {
    profile: Profile;
    onProfileChange: (profile: Profile) => void;
    hasCustomAttributes: boolean;
}
